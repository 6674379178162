import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Field } from 'react-final-form';

import { SkeletonRows } from 'js/components/spinner';
import { emailSubject, emailText } from 'js/defaults';

const EmailForm = ({loading, onSubmit, submittedStatus, processId, jobDir}) => {

  const [passesReCaptcha, setPassesReCaptcha] = useState(false);
  const [, setHasInvalidEmail] = useState(true);
  const [, setHasInvalidSubject] = useState(false);
  const [, setHasInvalidText] = useState(false);
  const [pluralEmails, setPluralEmails] = useState(false);

  const onReCaptchaChange = value => {
    setPassesReCaptcha(!!value);
  };

  /* Handle Forward Results Email Form */
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  const onSubmitting = async values => {
    await sleep(300);
    const v = {
      "jobDir" : values.jobDir,
      "processId" : values.processId,
      "emailAddresses" : values.emailAddress.toLowerCase().trim().split(emailSplitter),
      "emailText" : values.emailText,
      "emailSubject" : values.emailSubject
    }
    await onSubmit(v);
  };

  const emailSplitter = /[\s+,;]+/;
  const emailTest = /\S+@\S+\.\S+/;

  const emailValidator = (emailValue) => {
    const emailAddresses = emailValue.toLowerCase().trim().split(emailSplitter);
    // failsafe
    if (emailAddresses.length === 0) return false;
    setPluralEmails(emailAddresses.length > 1);
    return emailAddresses.reduce((acc, address) => {
      return acc && emailTest.test(address);
    }, true);
  };

  return (
    <div className="emailForm">

      { submittedStatus && (
        <div className="emailForm">
          <h3 className="push">
            {pluralEmails && ('Emails ')}
            {!pluralEmails && ('Email ')}
            successfully submitted.
          </h3>
          <p>It is safe to leave this page if you do not wish to wait to download your results. The average wait time is 15 - 30 minutes. If you have not received your results, please check your spam, try again, or <a href="https://www.ieee.org/about/contact.html">contact us.</a></p>
        </div>
      )}

      { !submittedStatus && (
        <div className="emailForm">

          <h3 className="push">
            Email my results:
          </h3>

          { loading 
            ? (<SkeletonRows rows={7} />) 
            : (
              <Form onSubmit={onSubmitting}
                initialValues={{ 
                  referenceFormat: 'bibtext',
                  //emailFrom: emailFrom,
                  emailSubject: emailSubject,
                  emailText: emailText,
                  processId: processId,
                  jobDir: jobDir,
                }}
                validate={values => {
                  const errors = {};
                  if (!values.emailSubject || !values.emailSubject.trim()) {
                    errors.emailSubject = 'required';
                    setHasInvalidSubject(true);
                  } else {
                    setHasInvalidSubject(false);
                  }
                  if (!values.emailText || !values.emailText.trim()) {
                    errors.emailText = 'required';
                    setHasInvalidText(true);
                  } else {
                    setHasInvalidText(false);
                  }
                  if (!values.emailAddress || !values.emailAddress.trim()) {
                    errors.emailAddress = 'required';
                    setHasInvalidEmail(true);
                  } else if (values.emailAddress && !emailValidator(values.emailAddress)) {
                    setHasInvalidEmail(true);
                    errors.emailAddress = 'invalid';
                  } else {
                    setHasInvalidEmail(false);
                  }
                  return errors;
                }}>
              {({ handleSubmit, 
                submitting, 
                pristine, 
                values, 
                invalid 
              }) => { 
                return (
                  <form onSubmit={handleSubmit} className="stacked">

                    <Field name="jobDir">
                      {() => <input type="hidden" name="jobDir" value={jobDir} /> }
                    </Field>
                    
                    <Field name="processId">
                      {() => <input type="hidden" name="processId" value={processId} /> }
                    </Field>

                    <Field name="emailAddress">
                      {({ input, meta }) => (
                        <>
                          <p>
                            Please enter one or more recipients. Separate 
                            multiple recipients with commas, semicolons, or spaces. 
                          </p>
                          <p className={meta.error && meta.touched ? 'error' : ''}>
                            <textarea {...input} className="t640 t80" type="text" placeholder="Email addresses" />
                          </p>
                        </>
                      )}
                    </Field>

                    <Field name="emailSubject">
                      {({ input, meta }) => (
                        <p className={meta.error && meta.touched ? 'error' : ''}>
                          <label htmlFor="emailSubject">Subject:</label>
                          <input {...input} className="i640" type="text" placeholder="Email subject" />
                        </p>
                      )}
                    </Field>

                    <Field name="emailText">
                      {({ input, meta }) => (
                        <p className={meta.error && meta.touched ? 'error' : ''}>
                          <label htmlFor="emailText">Text:</label>
                          <textarea {...input} className="t640 t120" type="text" placeholder="Email text"  />
                        </p>
                      )}
                    </Field>

                    <div className="recaptcha">
                      <ReCAPTCHA
                        sitekey="6Lex0s0ZAAAAAABr_Dg6EKFHudBN5svLJYt6a14M"
                        onChange={onReCaptchaChange}
                      />
                    </div> 
                    <p className="push">
                      <button className={(pristine || invalid || !passesReCaptcha) ? 'disabled' : ''} type="submit" disabled={submitting || pristine || invalid || !passesReCaptcha}>Submit Email</button>
                    </p>
                  </form>
                )}}
              </Form>
            )
          }
        </div>
      )}
    </div>
  )
};

export default EmailForm;
