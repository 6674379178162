import { useState, useEffect, useRef } from 'react';

/* https://www.robinwieruch.de/local-storage-react */
export const useStateWithSessionStorage = sessionStorageKey => {
  const [value, setValue] = useState(
    sessionStorage.getItem(sessionStorageKey) || ''
  );
  useEffect(() => {
    sessionStorage.setItem(sessionStorageKey, value);
  }, [value]); // eslint-disable-line 
  return [value, setValue];
};

/* https://overreacted.io/making-setinterval-declarative-with-react-hooks/ */
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  // [callback] defines the dependency that updates the hook on change
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval. [delay] is the dependency
  useEffect(() => {
    // on 'tick' run the savedCallback function that we passed in originally
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      // This return is the "unmount" cleanup function
      return () => clearInterval(id);
    }
  }, [delay]);
};