import React from 'react';

export const Spinner = () => {

  const width = 100;
  const height = 100;
  const cX = 50;
  const cY = 50;
  const cR = Math.min(cX, cY) * 0.8;

  return (
    <div className="spinnerPage">
      <span className="j">Loading...</span>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={width} height={height}>
        <defs>
        <linearGradient id="myGradient">
          <stop offset="0"  stopColor="white" />
          <stop offset="0.4"  stopColor="white" />
          <stop offset="0.8" stopColor="white" stopOpacity="0" />
        </linearGradient>
        </defs>
        <circle cx={cX} cy={cY} r={cR} transform="rotate(-45 50 50)" stroke="url('#myGradient')" strokeWidth="15" fill="none" />
        <path d={`M 10 50 L 10 50 A 40 40, 0, 0 1, 50 10`} stroke="white" strokeWidth="15" fill="none" />
      </svg>
    </div>
  );
};

export const SpinnerSpot = () => {
  return (
    <div className="spinnerSpot">
      <span className="spinnerRect spinnerRect1"></span>
      <span className="spinnerRect spinnerRect2"></span>
      <span className="spinnerRect spinnerRect3"></span>
    </div>
  );
};

export const SkeletonRows = ({ rows = 5 }) => {
  const rowWidths = [...Array(rows)].map((a, i) => 50 + parseInt(Math.random()*11));
  return (
    <div className="skeleton">
      <span className="j">Loading...</span>
      {rowWidths.map((w, i) => (
        <div key={i} className="skeletonRow skeletonPulse" style={{width: `${w}%`}} />
      ))}
    </div>
  )
}