import React from 'react';
import { useHistory } from "react-router-dom";

const Link = ({href = '#', label = '', className = '', target = '_blank', clickEffect, children}) => {

  let history = useHistory();

  const clickHandler = event => {
    if (clickEffect) {
      clickEffect(event);
    }
    const route = event.currentTarget.getAttribute('href');
    if (route.charAt(0) === '/' && target !== '_self') {
      event.preventDefault();
      history.push(route);
    }
  };

  const rel = target === "_blank" ? "noopener noreferrer" : "";

  return (
    <a href={href} onClick={clickHandler} target={target} className={className} rel={rel}>{label}{children}</a>
  );
};

export default Link;