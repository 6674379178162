import React, { useState, useEffect } from 'react';

const TextEntry = ({id, selectText, reference, clearForm}) => {
  const [textEntryStatus, setTextEntryStatus] = useState('ready');
  const [focusHappened, setFocusHappened] = useState('');
  const [textEntryValue, setTextEntryValue] = useState('');

  useEffect(() => {
    setTextEntryValue('');
  }, [clearForm]);

  const onOver = event => {
    event.preventDefault();
    if (textEntryStatus !== 'entry') {
      setTextEntryStatus('over');
    }
  }

  const onOut = event => {
    event.preventDefault();
    if (textEntryStatus !== 'entry') {
      setTextEntryStatus('ready');
    }
  }

  const onClick = event => {
    event.preventDefault();
    reference.current.focus();
  }

  const onFocus = event => {
    setTextEntryStatus('entry');
    setFocusHappened('second');
    selectText(textEntryValue);
  }

  const onBlur = event => {
    event.preventDefault();
    setTextEntryStatus('ready');
  }

  const onChange = event => {
    setTextEntryValue(event.target.value);
    selectText(event.target.value);
  }

  return (
    <div className={`textEntry ${textEntryStatus}`}
      onMouseEnter={onOver}
      onMouseLeave={onOut}      
      onClick={onClick}
    >
      <textarea id={id} 
        ref={reference}
        className={focusHappened}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        value={textEntryValue}
      ></textarea>
    </div>
  )
}

export default TextEntry;