/* Default values used by Providers and Presenters */

export const uploadFormats = [ "txt", "doc", "docx", "tex", "bbl"];

//export const emailFrom = "IEEE-ReferencePreparation@ieee.org";
//export const emailFrom = "IEEE Reference Preparation Assistant <noreply@ieee.org>";

export const emailSubject = "IEEE Reference Preparation Assistant Results";

export const emailText = `Please see the attached file containing IEEE Reference Preparation Assistant results.

Thank you!

IEEE Reference Preparation Assistant`
