import React, { useEffect, useRef } from 'react';
import { isIE } from 'react-device-detect';

const FileHandler = ({ 
  id,
  fileState,
  uploadStatus, 
  currentFile, 
  selectFile, 
  enableUpload, 
  reference 
}) => {

  const filer = useRef();

  useEffect(() => {
    resetFiler();
  });

  const resetFiler = () => {
    filer.current.value = '';
  };

  const onTabbed = event => {
    event.preventDefault();
    if (currentFile && uploadStatus === 'text') {
      selectFile(currentFile);
    }
  }

  const onFileClick = event => {
//    event.preventDefault();
    if (enableUpload) {
      if (!currentFile && !isIE) {
        filer.current.click();
      } else if (currentFile && uploadStatus === 'text') {
        selectFile(currentFile);
      } else if (!isIE) {
        filer.current.click();
      }
    }
  };

  const onFileKeyPress = event => {
    if ((event.keyCode === 13 || event.keyCode === 32) && !isIE) {
      onFileClick(event);
    }
  }; 
    
  const onFileSelect = event => {
    event.preventDefault();
    const file = event.currentTarget.files[0];
    selectFile(file);
  };
  
  const onClear = event => {
    event.preventDefault();
    event.stopPropagation();
    if (enableUpload) {
      resetFiler();
      selectFile();  
    }
  };
  
  // The 'legacy' css className if applied to the file element will reveal it for traditional upload.
  // Additional javascript is required to make that actually work.
  return (
    <span id={id} className={isIE ? 'fileUpload isIE' : 'fileUpload' }>
      <input type="file" className="modern" onChange={onFileSelect} ref={filer} />
      <span 
        className={`fileHandler ${fileState === 'error' ? 'error' : ''} ${currentFile !== null ? 'hasFile': ''}`} 
        tabIndex="0" 
        ref={reference} 
        onFocus={onTabbed} 
        onKeyDown={onFileKeyPress} 
        onClick={onFileClick}>
        { currentFile !== null ? 
            <span>{currentFile.name} <button className="icon canceller" onClick={onClear}><span className="icon-trash"></span></button></span>
            : !isIE ? <span className="link">Upload</span> : null
        }
      </span>
      { currentFile === null ?
        isIE ? 'Drag and drop a file anywhere on this page.' 
          : ' your references or your entire article. You may also drag and drop a file anywhere on this page.' 
        : null
      }
    </span>
  )
}

export default FileHandler;
