import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import ProcessProvider from 'js/contexts/processProvider';
import Upload from 'js/pages/upload';
import Processing from 'js/pages/processing';
import Results from 'js/pages/results';
import NavBar from 'js/components/navBar';
import Footer from 'js/components/footer';

//require('es6-object-assign').polyfill();
//require('es6-promise').polyfill();

const App = () => (
  <Router>
    <ProcessProvider>
      <NavBar />
      <main id="main">
        <Switch>
          <Route exact path="/" component={Upload} />
          <Route exact path="/processing" component={Processing} />
          <Route exact path="/results" component={Results} />
          <Redirect from='*' to='/' />
        </Switch>
      </main>
      <Footer />
    </ProcessProvider>
  </Router>
)

export default App;
