import {isIE} from 'react-device-detect';

const UPLOAD_TEXT_TYPE = 'TEXT';
const UPLOAD_TEXT = '/uploadtext';
const UPLOAD_FILE = '/mpupload';
const FORWARD_RESULTS = '/forwardresults';
const CANCEL_JOB = '/canceljob';
const GET_STATUS = 'status';

export const getStatus = (processId, onSuccess, onError) => {
  // If processId is not provided, backend returns stored ID from userSession:
  //   myUUID = userSession.getJobUUID();
  // This can throw an error state if the userSession doesn't have one.
  const path = GET_STATUS + '/' + processId;
  const payload = new FormData();
  apiCall('GET', path, payload, onSuccess, onError);
}

export const postText = (text, onSuccess, onError) => {
  const payload = new FormData();
  payload.append('text', text);
  payload.append('type', UPLOAD_TEXT_TYPE);
  apiCall('POST', UPLOAD_TEXT, payload, onSuccess, onError);
};

export const postFile = (file, onSuccess, onError) => {
  const payload = new FormData();
  payload.append('file', file, file.name);
  apiCall('POST', UPLOAD_FILE, payload, onSuccess, onError);
};

export const postForward = (form, onSuccess, onError) => { 
  const toList = form.emailAddresses.map(item => item.trim());
  const payload = new FormData();
  payload.append('emailAddresses', toList);
  payload.append('emailSubject', form.emailSubject);
  payload.append('emailText', form.emailText);
//  payload.append('uuid', form.processId);
//  payload.append('referenceFormat', form.referenceFormat);
  payload.append('jobDir', form.jobDir);
  apiCall('POST', FORWARD_RESULTS, payload, onSuccess, onError);
};

export const getCancel = (onSuccess, onError) => {
  const payload = new FormData();
  apiCall('GET', CANCEL_JOB, payload);
};

export const apiCall = (method, path, body, onSuccess, onError) => {
  const r = {};
  const params = (method === 'POST' ? { method: method, body: body } : { method: method });
  if (isIE) {
    path += '?t=' + Date.now();
  }
  fetch(path, params).then(response => {
    r.status = response.status;
    return response.json();
  }).then(
    response => {
      r.body = response;
      return r;
    }
  ).then(
    success => onSuccess ? onSuccess(success) : null
  ).catch(
    // Either the backend has not responded, or the promise has not resolved.
    // The latter error can be caused by an error in the onSuccess handler.
    error => {
      return onError ? onError(error) : null;
    }
  );
};

