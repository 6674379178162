import React from 'react';
import Link from 'js/components/link';
import { footerLinks, copyright, tagline } from 'js/shell';

const Footer = () => {
  return (
    <footer id="footer">
      <div className="nav">
        <ul role="navigation">
          { footerLinks.map(item => {
            return <li key={item.key} className={item.className}><Link href={item.href} label={item.label} target={item.target} /></li>
          }) }
        </ul>
      </div>
      <p>
        {copyright}{' '}{tagline}
      </p>
    </footer>
  )
}

export default Footer;