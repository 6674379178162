import React from 'react';

const Flex = ({ css = '', columns = false, children }) => {
  const columnClass = columns ? 'columns' : '';
  return (
    <div className={`flex ${css} ${columnClass}`}>
      {children}
    </div>
  )
}

export const Flexed = ({css = '', children}) => {
  return (
    <div className={`flexed ${css}`}>
      {children}
    </div>
  );
}

export default Flex;

