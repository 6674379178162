import React from 'react';

/* Default Navigation */

export const navbarLinks = [{
  key: 'ieeeorg',
  href: 'https://www.ieee.org/',
  label: 'IEEE.org'
},{
  key: 'ieeexplore',
  href: 'https://ieeexplore.ieee.org/Xplore/home.jsp',
  label: 'IEEE Xplore Digital Library'
},{
  key: 'ieeestandards',
  href: 'https://standards.ieee.org/',
  label: 'IEEE Standards'
},{
  key: 'ieeespectrum',
  href: 'https://spectrum.ieee.org/',
  label: 'IEEE Spectrum'
},{
  key: 'authorcenter',
  href: 'https://ieeeauthorcenter.ieee.org/',
  label: 'IEEE Author Center'
}];
/*
},{
  key: 'moresites',
  href: 'https://www.ieee.org/sitemap.html',
  label: 'More Sites',
},{
  key: 'joinieee',
  href: 'https://ieeeauthorcenter.ieee.org/ieee-membership-advantages/',
  label: 'Join IEEE',
  className: 'join'
}];
*/

export const footerLinks = [{
  key: 'home',
  href: '/',
  label: 'Home',
  target: '_self'
},{
  key: 'contact',
  href: 'https://www.ieee.org/about/contact_center/index.html?WT.mc_id=hpf_contact',
  label: 'Contact and Support'
},{
  key: 'accessibility',
  href: 'https://www.ieee.org/accessibility-statement.html?WT.mc_id=hpf_acc',
  label: 'Accessibility'
},{
  key: 'privacy',
  href: 'https://www.ieee.org/security-privacy.html?WT.mc_id=hpf_privacy',
  label: 'Privacy & Opting Out of Cookies'
},{
  key: 'nondiscrimination',
  href: 'https://www.ieee.org/about/corporate/governance/p9-26.html',
  label: 'Nondiscrimination Policy'
}];

const year = new Date().getFullYear();

export const copyright = <span>© Copyright {year} IEEE – All rights reserved. Use of this website signifies your agreement to the <a href="https://www.ieee.org/site-terms-conditions.html" target="_blank" rel="noopener noreferrer">IEEE Terms and Conditions</a>.</span>
export const tagline = <span>A not-for-profit organization, IEEE is the world's largest technical professional organization dedicated to advancing technology for the benefit of humanity.</span>;
