import React from "react";

// Create a context that includes state and functions
export const ProcessContext = React.createContext({
  uploadMessage: null,
  uploadState: null,
  isLoading: false,
  showLoading: () => {},
  hideLoading: () => {},
  setUploadState: () => {},
  setUploadMessage: () => {},
});
