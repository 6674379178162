import React, { useState, useEffect, useContext, useRef } from 'react';
import { Spinner } from 'js/components/spinner';
import Link from 'js/components/link';
import Flex, { Flexed } from 'js/components/flex';
import ConfirmModal from 'js/components/confirmModal';
import SubSection from 'js/components/subSection';
import TextEntry from 'js/components/textEntry';
import FileHandler from 'js/components/fileHandler';
import { useHistory } from "react-router-dom";
import * as hooks from "js/hooks"
import { ProcessContext } from 'js/contexts/processContext';
import { postText, postFile } from 'js/api/fileApi.js';
import { uploadFormats } from 'js/defaults';

const Upload = () => {

  // eslint-disable-next-line
  const [processId, setProcessId] = hooks.useStateWithSessionStorage(
    'processId'
  );

  // eslint-disable-next-line
  const [processName, setProcessName] = hooks.useStateWithSessionStorage(
    'processName'
  );

  // eslint-disable-next-line
  const [emailState, setEmailState] = hooks.useStateWithSessionStorage(
    'emailState'
  );

  const { 
    isLoading,
    showLoading,
    hideLoading,
    uploadState,
    uploadMessage,
    setUploadState,
    setUploadMessage,
  } = useContext(ProcessContext);

  const [uploadStatus, setUploadStatus] = useState('ready');
  const [uploadError, setUploadError] = useState(false);
  const [enableUpload, setEnableUpload] = useState(true);
  const [fileState, setFileState] = useState();
  const [clearForm, setClearForm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [currentText, setCurrentText] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);

  const defaultFormState = !showError && !!currentText ? 'ready' : 'disabled';
  const [formState, setFormState] = useState(defaultFormState);

  const uploadElement = useRef(null);
  const textElement = useRef(null);

  const history = useHistory();

  useEffect(() => {
    document.body.addEventListener('dragover', onDragOver);
    document.body.addEventListener('dragleave', onDragLeave);
    document.body.addEventListener('drop', onDrop);
    return () => {
      //  remove event listener when the component unmounts
      document.body.removeEventListener('dragover', onDragOver);
      document.body.removeEventListener('dragleave', onDragLeave);
      document.body.removeEventListener('drop', onDrop);
    };
  }, []); // eslint-disable-line 

  useEffect(() => {
    resetState();
  }, []); // eslint-disable-line 

  const resetState = () => {
    setUploadStatus('ready');
    setCurrentFile(null);
    setCurrentText('');
    setEnableUpload(true);
    setClearForm(false);
    setShowError(false);
    setProcessId('');
    setEmailState('');
    setProcessName('');
    hideLoading();
  }

  const onDragOver = event => {
    event.preventDefault();
    setUploadStatus('drag');
  }

  const onDragLeave = event => {
    event.preventDefault();
    setUploadStatus('ready');
  }

  const onDrop = (event) => {
    if (enableUpload) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      selectFile(file);
      uploadElement.current && uploadElement.current.focus();
    }
  };

  const validateFile = file => {
    if (file && file.name) {
      const extension = file.name.split('.').pop();
      if (extension && uploadFormats.indexOf(extension.toLowerCase()) > -1) {
        setUploadError(false);
        return 'ready';
      }
      setUploadError(true);
      return 'error';
    }
  }

  const selectFile = (file) => {
    if (file) {
      setCurrentFile(file);
      const fileStatus = validateFile(file);
      setFileState(fileStatus);
      if (!!currentText) {
        setFormState('disabled');
        setShowError(true);
      } else {
        setFormState(fileStatus);
      }
    } else {
      setCurrentFile(null);
      setFileState(null);
      setUploadStatus('ready');
      setShowError(false);
      const newFormState = !!currentText ? 'ready' : 'disabled';
      setFormState(newFormState);
    }
  };

  const selectText = (text) => {
    if (text && text.trim().length > 0) {
      setCurrentText(text.trim());
      if (!!currentFile) {
        setFormState('disabled');
        setShowError(true);
      } else {
        setFormState('ready');
      }
    } else {
      setCurrentText(null);
      setUploadStatus('ready');
      setShowError(false);
      setFormState(!currentFile ? 'disabled' : fileState);
    }
  };

  const clearCurrentText = (e) => {
    e.preventDefault();
    setClearForm(!clearForm);
    selectText('');
  }

  const clickSubmitHandler = (e) => {
    e.preventDefault();
    //console.info('currentFile', currentFile);
    if (enableUpload) {
      setEnableUpload(false);
      setFormState('loading');
      showLoading();
      if (currentText) {
        postText(currentText, submitSuccess, submitError);
      } else {
        postFile(currentFile, submitSuccess, submitError);
      }
    }
  };

  const submitSuccess = (success) => {
    //console.info('submitSuccess success.body', success.body);
    const r = success.body;
    if (r.result === "ERROR") {
      // Successful API, but server returns an error
      let msg = '';
      switch (r.error) {
        case 'UNKNOWN' : 
          msg += 'Unknown file type. File cannot be processed.';
          break;
        case 'FILE_TOO_LARGE' : 
          msg += r.message;
          break;
        default:
          msg = r.message;
      }
      setUploadState('error');
      setUploadMessage(msg);
      return;
    }
    hideLoading();
    let processName;
    if (currentFile) {
      processName = currentFile.name;
    } else if (currentText) {
      processName = currentText.slice(0,32);
      if (currentText.length > 32) {
        processName += '…'; 
      }
    }
    setProcessId(r.id);
    setProcessName(processName);
    history.push('/processing');
  };

  const submitError = (error) => {
    console.info('submitError', error);
    setUploadState('error');
    let msg = 'Sorry. The Reference Preparation service isn\'t responding right now.';
    //let msg = error; // didn't work, syntax error, HTML needs to be string-escaped, etc.
    setUploadMessage(msg);
  };

  const closeExitModal = () => {
    setFormState('ready');
    setUploadState('ready');
    setEnableUpload(true);
    hideLoading();
  }

  const closeErrorModal = () => {
    setUploadError(false);
  }

  return (
    <div id="upload">

      <ConfirmModal
        isOpen={uploadError}
        handleCloseModal={closeErrorModal}
        className="confirm"
      >
        <div className="uploadError">
          <div className="statusIcon">
            <span className="icon-status-alert">
              <span className="j">Alert!</span>
            </span>
          </div>
          <div className="statusMessage">
            <h2>
              Invalid File Type
            </h2>
            <p>
              Only files with a .tex, .txt, .doc, or .docx extension will be accepted.
            </p>
            <div className="modalActions">
              <button className="errorReset lg" onClick={closeErrorModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </ConfirmModal>

      <ConfirmModal
        isOpen={isLoading}
        handleCloseModal={closeExitModal}
        className={uploadState === 'error' ? 'confirm' : 'loader'}
      >
        { uploadState === 'ready' && (
          <Spinner />
        )}
        { uploadState === 'error' && (
          <div className="uploadError">
            <div className="statusIcon">
              <span className="icon-status-alert">
                <span className="j">Alert!</span>
              </span>
            </div>
            <div className="statusMessage">
              <h2>
                Submission Error
              </h2>
              <p>
                { uploadMessage }
              </p>
              <div className="modalActions">
                <button className="errorReset lg" onClick={closeExitModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </ConfirmModal>
      <SubSection>
        <div className={`bounds dropZone ${uploadStatus}`}>
          <Flex columns>
            <Flexed css="p25">
              <h2>
                Improve your references before submitting your article for peer review.
              </h2>

              <p>
                Receive a correctly formatted reference list in IEEE style by pasting your references or uploading your references or article. The following file formats can be uploaded:              
              </p>

              <ul>
                <li>Word: .docx, .doc</li>
                <li>LaTeX: .tex, .bbl (in UTF-8 format)</li>
                <li>Text: .txt (in UTF-8 format)</li>
              </ul>

              <p>
                For best results, each reference should be numbered and separated by a line break.
              </p>

              <p>
                You will receive a ZIP package containing your corrected references plus a report with notes and recommended steps. You can download this ZIP package or have it emailed.
              </p>
            </Flexed>
            <Flexed>
              <p className="push radio" style={{paddingTop: '3px'}}>
                Copy and paste text 
                {
                  !!currentText ?
                    <Link className="fright" clickEffect={clearCurrentText} label="clear" />
                    : null
                }
              </p>
              
              <TextEntry id="text"
                currentText={currentText}
                selectText={selectText}
                clearForm={clearForm}
                reference={textElement}
              />

              <p style={{padding: '12px 0 4px 2px', textTransform: 'uppercase', marginBottom: 0}}><strong>Or</strong></p>

              <p>
                  <FileHandler 
                    id="fileUpload"
                    fileState={fileState}
                    reference={uploadElement}
                    enableUpload={enableUpload}
                    uploadStatus={uploadStatus}
                    selectFile={selectFile}
                    currentFile={currentFile}
                  />
              </p>

              <p className="push">
                <button disabled={formState !== 'ready'} className={`${formState} upload lg`} onClick={clickSubmitHandler}>
                  Check Your References
                </button>
                { showError && fileState !== 'error' ? 
                  (
                    <span className="errormsg">
                      You cannot submit both a file and text.
                    </span>
                  ) : null
                  }
              </p>
            </Flexed>
          </Flex>
        </div>
      </SubSection>
    </div>
  )
}

export default Upload;
