import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from 'react';
import Link from 'js/components/link';
import ConfirmModal from 'js/components/confirmModal';
import EmailForm from 'js/components/emailResultsForm';
import { useHistory } from "react-router-dom";
import SubSection from 'js/components/subSection';
import { SpinnerSpot } from 'js/components/spinner';
import * as hooks from "js/hooks";
// import * as defaults from 'js/defaults';
import { ProcessContext } from 'js/contexts/processContext';
import { getStatus, getCancel, postForward } from 'js/api/fileApi.js';

const POLL_INTERVAL = 5000; // 30 seconds for IE11, 5 seconds otherwise 
const POLL_RETRIES = 24; // Retry on error before asking user to confirm continue
const POLL_MAX = 180; // 180 polls @5 seconds each is 15 minutes
const POLL_LIST = false; // Set to true to see a list of results

const pollMsg = {
  'no_request' : 'No request is running.',
  'pending' : 'Waiting.',
  'retry' : 'Processing status cannot be retrieved. Retrying.',
  'unknown' : 'Processing status cannot be retrieved. Retrying.',
  'complete' : 'Processing complete.',
  'no_results' : 'No results returned. Please check your entry and try again later.'
}

const Processing = () => {

  const [jobDir, setJobDir] = hooks.useStateWithSessionStorage(
    'jobDir'
  );

  const [processId] = hooks.useStateWithSessionStorage(
    'processId'
  );

  const [processName] = hooks.useStateWithSessionStorage(
    'processName'
  );

  const [emailState, setEmailState] = hooks.useStateWithSessionStorage(
    'emailState'
  );

  const [exitModalIsOpen, setExitModalIsOpen] = useState(false);
  const [startOverExit, setStartOverExit] = useState(false);
  const [noProcessIdModalIsOpen, setNoProcessIdModalIsOpen] = useState(false);
  const [maxPollingModalIsOpen, setMaxPollingModalIsOpen] = useState(false);
  const [retriesModalIsOpen, setRetriesModalIsOpen] = useState(false);
  const [processingErrorModalIsOpen, setProcessingErrorModalIsOpen] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [emailErrorModalIsOpen, setEmailErrorModalIsOpen] = useState(false);
  const [processingState, setProcessingState] = useState({
    steps: [],
    msg: pollMsg['pending'],
    formState: 'pending',
    currentStep: 0
  });
  const [processedFilePath, setProcessedFilePath] = useState('');
  const [pollCount, setPollCount] = useState(0);
  const [pollRetries, setPollRetries] = useState(0);
  const [playPoll, setPlayPoll] = useState(true);

  const history = useHistory();

  const {
    hideLoading
  } = useContext(ProcessContext);

  const stepRef = useRef(null);
  const processingRef = useRef(null);

  useLayoutEffect(() => {
    offset();
  }, [processingState]);

  const offset = () => {
    if (stepRef.current && processingRef.current && POLL_LIST) {
      processingRef.current.scrollTop = 1000; // large enough value to always scroll to bottom
    }
  };

  useEffect(() => {
    hideLoading(); // Failsafe
    if (!processId || processId === '') {
      setPlayPoll(false)
      setNoProcessIdModalIsOpen(true);
      setProcessingState({
        steps: [],
        msg: pollMsg['no_request'],
        formState: 'pending',
        currentStep: 0
      });
      setEmailState(null);
      setEmailSubmitted(false);
    } else {
      if (emailState === processId) {
        setEmailSubmitted(true);
      }
      setPlayPoll(true);
      setProcessingState({
        steps: [],
        msg: pollMsg['pending'],
        formState: 'pending',
        currentStep: 0
     });
    }
  }, []); // eslint-disable-line 

  const poll = () => {
    if (pollCount > POLL_MAX) {
      if (!emailErrorModalIsOpen) {
        setPlayPoll(false)
        setMaxPollingModalIsOpen(true);  
      }
    } else {
      getStatus(processId, pollSuccess, pollError);
    }
  };

  hooks.useInterval(() => {
    if (playPoll) {
      setPollCount(pollCount + 1);
      poll();
    }
  }, POLL_INTERVAL);

  const pollSuccess = (success) => {
    const r = success.body;
    setPollRetries(0);
    if (r.state === 'PROCESSING' && r.data && r.data.length > 0) {
      setJobDir(r.data[0]['jobDir']);
      const steps = r.data.filter(
        step => step.statusMessage.search('step started') > -1
      ).map(step => {
        return {
          msg: 'Processing step ' + step.stepNum + ' of ' + step.numSteps + '.',
          stepNum: step.stepNum,
          statusCode: step.statusCode.toLowerCase()
        }
      });
      const realStep = steps.length - 1;
      const realState = r.data[r.data.length - 1];
      const realStatus = realState.statusCode.toLowerCase();
      if (realStatus === 'complete') {
        let currentMsg, formState;
        setPlayPoll(false);
        setProcessedFilePath(realState.resultLink);
        currentMsg = realState.resultLink ? pollMsg['complete'] : pollMsg['no_results'];
//        formState = realState.resultLink ? 'complete' : 'complete';
        formState = realState.resultLink ? 'complete' : 'alert';
        setProcessingState({
          steps: [...steps],
          msg: currentMsg,
          formState: formState,
          currentStep: realStep
        });  
      } else if (realStatus === 'error') {
        setPlayPoll(false);
        setProcessingState({
          steps: [...steps],
          msg: realState.statusMessage,
          formState: 'error',
          currentStep: realStep
        });  
        setProcessingErrorModalIsOpen(true);
      } else {
        const nextStep = realStep > processingState.currentStep ? processingState.currentStep + 1 : realStep;
        if (steps[processingState.currentStep]['msg']) {
          setProcessingState({
            steps: [...steps],
            msg: steps[processingState.currentStep]['msg'],
            formState: 'processing',
            currentStep: nextStep
          });    
        }
      }
    } else {
      setProcessingState({
        steps: [...processingState.steps],
        msg: pollMsg['unknown'],
        formState: 'unknown',
        currentStep: processingState.currentStep
      });
      pollError('unknown');
    }
  };

  const pollError = (error) => {
    console.warn('Polling Error', error);
    setPollRetries(pollRetries + 1);
    if (pollRetries > POLL_RETRIES) {
      if (!emailErrorModalIsOpen) {
        setPlayPoll(false);
        setPollRetries(0);
        setRetriesModalIsOpen(true);
      }
    }
  };

  const onConfirmCancelProcessing = () => {
    setStartOverExit(false);    
    setExitModalIsOpen(true);
  };

  const onConfirmStartOver = () => {
    // test emailSubmitted && state of Download button has been clicked
    if (emailSubmitted || downloadClicked) {
      history.push('/upload');      
    } else {
      setExitModalIsOpen(true);
      setStartOverExit(true);    
    }
  };

  const onCancelProcessing = () => {
    setExitModalIsOpen(false);
    setNoProcessIdModalIsOpen(false);
    setRetriesModalIsOpen(false);
    // We will not expose cancel API status to the user, so there is no
    // "success" or "error" callback.
    getCancel();
    history.push('/upload');
  };

  const onContinueToWait = () => {
    setRetriesModalIsOpen(false);
    setMaxPollingModalIsOpen(false);
    setPollCount(0);
    setPlayPoll(true);
  };

  const closeExitModal = () => {
    setExitModalIsOpen(false);
    setStartOverExit(false);    
    if (pollCount > POLL_MAX) { return; }
//    setPlayPoll(true);
  };

  const onForwardSubmit = values => {
    postForward(values, submitForwardSuccess, submitForwardError);
    // Provide processId as hidden field? Then the submit could validate
    // the processId against the user's email address to prevent duplicate 
    // submissions. Could also assign success response of processId and
    // to local storage to prevent reinitializing the form on user refresh.
  };

  const submitForwardSuccess = (success) => {
    setEmailSubmitted(true);
    setEmailState(processId);
    setEmailErrorModalIsOpen(false);
  };

  const submitForwardError = (error) => {
    setEmailSubmitted(false);
    setEmailErrorModalIsOpen(true);
  };

  const onCancelEmailError = () => {
    setEmailErrorModalIsOpen(false);
  };

  return (
    <div id="processing">
      <ConfirmModal
        isOpen={exitModalIsOpen}
        handleCloseModal={closeExitModal}
        className="confirm"
      >
        { startOverExit ? (
          <>
            <h2>Abandon Results?</h2>
            <p>
              You have not selected to download or email your results. If you leave this page now you will not be able to retrieve your processed file.
            </p>
            <div className="modalActions">
              <button className="lg secondary" onClick={closeExitModal}>Stay Here</button>
              <button type="submit" className="lg" onClick={onCancelProcessing}>Continue</button>
            </div>
          </>
        ) : (
          <>
            <h2>Confirm Cancel?</h2>
            <p>
              To cancel your reference processing request, click "Continue" below. You will return to the Upload screen.
            </p>
            <div className="modalActions">
              <button className="lg secondary" onClick={closeExitModal}>Stay Here</button>
              <button type="submit" className="lg" onClick={onCancelProcessing}>Continue</button>
            </div>
          </>
          ) } 
      </ConfirmModal>
      <ConfirmModal
        isOpen={noProcessIdModalIsOpen}
        handleCloseModal={onCancelProcessing}
        className="confirm"
      >
        <h2>No Request is Running</h2>
        <p>
          You do not have an active reference processing request. When you close this 
          window you will return to the Upload screen.
        </p>
        <div className="modalActions">
          <button type="submit" className="lg" onClick={onCancelProcessing}>OK</button>
        </div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={maxPollingModalIsOpen}
        handleCloseModal={onContinueToWait}
        className="confirm"
      >
        <h2>Slow Processing</h2>
        <p>
          Your request is taking a long time to process. This may be caused by the size or complexity of the request
          or by heavy demand on the processing server. You may cancel processing at any time.
        </p>
        { emailSubmitted ? (
          <p>
            Your reference request will still be emailed to you. 
          </p>
        ) : (
          <p>
            If you do not wish to wait, you may elect to have your results emailed to you.
          </p>
        )}
        <div className="modalActions">
          <button type="submit" className="lg" onClick={onContinueToWait}>OK</button>
        </div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={retriesModalIsOpen}
        handleCloseModal={onContinueToWait}
        className="confirm"
      >
        <h2>Can't Report Status</h2>
        <p>
          The reference processing service is not responding. Please check your internet connection. 
        </p>
        { emailSubmitted ? (
          <p>
            Your reference request will still be emailed to you. 
          </p>
        ) : (
          <p>
            If you do not wish to wait, you may elect to have your results emailed to you.
          </p>
        )}
        <div className="modalActions">
          <button type="submit" className="lg" onClick={onContinueToWait}>OK</button>
        </div>
      </ConfirmModal>      
      <ConfirmModal
        isOpen={processingErrorModalIsOpen}
        handleCloseModal={onCancelProcessing}
        className="confirm"
      >
        <h2>Processing Error</h2>
        <p>
          {processingState.msg}
        </p>
        <p>
          When you close this window you will return to the Upload screen.
        </p>
        <div className="modalActions">
          <button type="submit" className="lg" onClick={onCancelProcessing}>OK</button>
        </div>
      </ConfirmModal>      
      <ConfirmModal
        isOpen={emailErrorModalIsOpen}
        handleCloseModal={onCancelEmailError}
        className="confirm"
      >
        <h2>Email Submission Error</h2>
        <p>
          The email forwarding service is not responding. Your request was not received.
        </p>
        <div className="modalActions">
          <button type="submit" className="lg" onClick={onCancelEmailError}>Close</button>
        </div>
      </ConfirmModal>      
      <SubSection>
        <div className="bounds">

          { processingState.formState === 'complete' ? (
            <h2>
              Processing Complete
            </h2>
          ) : (
            <h2>
              Processing your input <strong className="processingName">{ processName }&hellip;</strong>
              { (processingState.formState === 'processing' || processingState.formState === 'pending') && (
                <button className="fright link" onClick={onConfirmCancelProcessing}>Cancel</button>
              )}
            </h2>
          )}

          <div className="processingPanel" ref={processingRef} style={{ height: POLL_LIST ? '130px' : 'auto' }}>
            <ul className="processingStatus" ref={stepRef}>
              { processingState.formState === 'pending' ? (
                <li className="status-pending">
                  <SpinnerSpot /> Waiting.
                </li>) : null }


              {POLL_LIST && processingState.steps.map((step, index) => {
                let stepClass = 'step';
                const lastItem = index === processingState.steps.length - 1;
                const stepIcon = step.statusCode === 'error' 
                  ? (<span className="icon-small icon-status-error"></span>) 
                  : (<span className="icon-small icon-status-ok"></span>);
                return (
                  <li key={index} className={stepClass}>
                    {step.msg}
                    { lastItem && processingState.formState === 'processing' && <SpinnerSpot /> }
                    { lastItem && processingState.formState !== 'processing' && stepIcon }
                    { !lastItem && stepIcon }
                  </li>
                )
              })}

              {!POLL_LIST && processingState.formState === 'processing' ? (
                <li>
                  <SpinnerSpot />
                  {processingState.msg}
                </li>
              ) : processingState.formState === 'complete' ? (
                  <li className="status-complete">
                    <span className="lip">
                      <span className="icon icon-status-ok"></span>
                      {processingState.msg}
                    </span>
                    <span className="lip half">
                      <button className="secondary" onClick={onConfirmStartOver}>Process Another</button>
                      <Link className="btn" clickEffect={(e) => setDownloadClicked(true)} href={processedFilePath} label="Download Results" target="_self" />
                    </span>
                  </li>
              ) : processingState.formState === 'error' || processingState.formState === 'unknown'  || processingState.formState === 'alert' ? (
                  <li class={'status-' + processingState.formState } ref={stepRef}>
                    <span className="lip">
                      <span className={'icon icon-status-' + processingState.formState}></span>
                      {processingState.msg}
                    </span>
                    <span className="lip push">
                      <button type="submit" className="lg" onClick={onCancelProcessing}>Start Over</button>
                    </span>
                  </li>
              ) : null }
            </ul>            
          </div>

          { ((processingState.formState === 'processing' || processingState.formState === 'pending') && !emailSubmitted) && (
            <>
              <p>
                You may take both or either of the following actions:
              </p>

              <ul>
                <li>Download the results when processing is complete.</li>
                <li>Email the results to yourself and others.</li>
              </ul>

              <p>
                Once you receive your results, you should confirm the accuracy of any corrections suggested by the tool.
              </p>
            </>
          )} 
          { (processingState.formState !== 'error' && processingState.formState !== 'unknown'  && processingState.formState !== 'alert') && (
            <div className="panel forward">
              <EmailForm loading={ processingState.formState === 'pending' } onSubmit={onForwardSubmit} submittedStatus={emailSubmitted} processId={processId} jobDir={jobDir} />
            </div>
          )}
        </div>
      </SubSection>
    </div>
  );
};

export default Processing;

