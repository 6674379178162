import React, { useEffect, useState } from 'react';
import Link from 'js/components/link';
import SubSection from 'js/components/subSection';
import ConfirmModal from 'js/components/confirmModal';
import * as hooks from "js/hooks"
import { useHistory } from "react-router-dom";

const Results = () => {
  
  const history = useHistory();

  const [processId] = hooks.useStateWithSessionStorage(
    'processId'
  );

  const [noProcessIdModalIsOpen, setNoProcessIdModalIsOpen] = useState(false);

  useEffect(() => {
    if (!processId || processId === '') {
      setNoProcessIdModalIsOpen(true);
    }
  }, []); // eslint-disable-line 

  const clickPrevHandler = e => {
    history.push('/upload');
  };

  const onCancelResultsPresentation = () => {
    setNoProcessIdModalIsOpen(false);
    history.push('/upload');
  };

  return (
    <div id="results">
      <ConfirmModal
        isOpen={noProcessIdModalIsOpen}
        handleCloseModal={onCancelResultsPresentation}
        className="confirm"
      >
        <h2>No Request is Running</h2>
        <p>
          You do not have an active reference processing request. When you close this 
          window you will return to the Upload screen.
        </p>
        <div className="modalActions">
          <button type="submit" className="lg" onClick={onCancelResultsPresentation}>OK</button>
        </div>
      </ConfirmModal>
      <SubSection>
        <div className="bounds">

          <h2> 
            Results
          </h2>

          <div className="flexColumns">

            <div className="flexColumn50">

              <p>
                Markup
              </p>
  
            </div>
            <div className="flexColumn50">
              <p>
                You may download your corrected references in one of the following formats:
              </p>

              <p>
                <select className="im120">
                  <option>BibTeX</option>
                  <option>LaTeX</option>
                </select>
              </p>

              <p>
                <button className="im120">Download</button>
              </p>

              <p>
                For more information on publishing with IEEE, please visit the{' '} 
                <Link href="https://ieeeauthorcenter.ieee.org/" label="IEEE Author Center" />.
              </p>

            </div>

          </div>

          <p>
            <button className="lg" onClick={clickPrevHandler}>Start Over</button>
          </p>

        </div>
      </SubSection>
    </div>
  )
}

export default Results;