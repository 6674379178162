import React from 'react';
import Link from 'js/components/link';
import Flex, { Flexed } from 'js/components/flex';
import { navbarLinks } from 'js/shell';

const NavBar = () => {
  return (
    <header role="banner">
      <div className="nav ieee-meta top-nav">
        <Flex>
          <Flexed>
            <ul role="navigation">
              { navbarLinks.map(item => {
                return <li key={item.key} className={item.className}><Link href={item.href} label={item.label} target={item.target} /></li>
              })}
            </ul>
          </Flexed>
          <Flexed css="logo">
            <Link href="https://www.ieee.org/" label={<img alt="IEEE" src="/images/ieee_white.svg" />} />            
          </Flexed>
        </Flex>
      </div>
      <div role="banner" className="banner">
        <Flex>
          <Flexed>
            <h1>
              <Link href='/' target='_self' className='title'>
                <span className='productName'>IEEE Reference Preparation Assistant</span>
              </Link>
            </h1>
          </Flexed>
          <Flexed css="edifix">
            <Link href="https://www.edifix.com/">
              <img src="/images/PoweredByEdifix.png" alt="Powered by Edifix" />
            </Link>
          </Flexed>
        </Flex>
      </div>
    </header>  
  );
};

export default NavBar;
