import React from 'react';
import Modal from 'react-modal';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const ConfirmModal = ({ className, isOpen, handleCloseModal, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      className={`modal ${className}`}
      shouldCloseOnOverlayClick={false}
      overlayClassName="overlay">
      <section className="section">
        <button className="icon modalCloser" onClick={handleCloseModal}>&times;</button>
        <div className="bounds">
          { children }
        </div>
      </section>
    </Modal>
  )
}

export default ConfirmModal;
