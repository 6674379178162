import React from 'react';

const SubSection = ({children}) => {
  return (
    <section>
      <div className="section bordered">
        {children}
      </div>
    </section>
  );
}

export default SubSection;