import React, { useState } from 'react';
import * as defaults from 'js/defaults';
import { ProcessContext } from 'js/contexts/processContext';

const ProcessProvider = ({children}) => {

  const showLoading = () => {
    setProcessState(prevState => {
      return {
        ...prevState,
        isLoading: true 
      }
    });
  };

  const hideLoading = () => {
    setProcessState(prevState => {
      return {
        ...prevState,
        isLoading: false
      }
    });
  };

  const setUploadState = (state) => {
    setProcessState(prevState => {
      return {
        ...prevState,
        uploadState: state
      }
    });
  };

  const setUploadMessage = (msg) => {
    setProcessState(prevState => {
      return {
        ...prevState,
        uploadMessage: msg
      }
    });
  };

  const initialProcessState = {
    uploadMessage: null,
    isLoading: false,
    uploadState: 'ready',
    showLoading,
    hideLoading,
    setUploadState,
    setUploadMessage,
  }

  const [processState, setProcessState] = useState(initialProcessState)

  return (
    <ProcessContext.Provider value={processState}>
      {children}
    </ProcessContext.Provider>
  )
}

export default ProcessProvider;
